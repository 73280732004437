import RoutesWrapper from "routes";
import "assets/styles/index.scss";
import { CartProvider } from "hooks/CartContext";
import TelegramBackButton from "components/TelegramBackButton";

function App() {
  return (
    <>
      <CartProvider>
        <TelegramBackButton/>
        <RoutesWrapper />
      </CartProvider>
    </>
  );
}

export default App;
