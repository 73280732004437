import React from "react";
import { useHooks } from "hooks"; // Assuming you use this hook for translations

const Loading = () => {
  const { t } = useHooks(); // Get the translation function

  return (
    <div className="loading_content">
      <div className="loader">
        {t("Loading...")}
      </div>
    </div>
  );
};

export default Loading;

