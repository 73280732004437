import React from "react";
import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined } from "@ant-design/icons";

import { useHooks } from 'hooks'
import useStore from "store";
import { privateRoutes } from "routes/data";

const { Header } = Layout;

interface IProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderComponent = ({ collapsed, setCollapsed }: IProps) => {
  const { get, location } = useHooks()

  const menus = privateRoutes.find((m) => m.path === get(location, 'pathname'))

  return (
    <Header className="flex justify-between items-center bg-[#fff] dark:bg-[#222638] p-0">
      <div>
        {/* <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      /> */}

        <span className="font-[500] text-[20px] dark:text-[#9EA3B5] text-black ml-[48px]">{menus?.title}</span>
      </div>
    </Header>
  );
};

export default HeaderComponent;
