import { lazy } from "react";

const NotFound = lazy(() => import("pages/notFound"));
const Home = lazy(() => import("pages/home"));
const Payment = lazy(() => import("pages/payment"));
const Product = lazy(() => import("pages/product"));
const Register = lazy(() => import("pages/register"));
const Categories = lazy(() => import("pages/categories"));
const Cart = lazy(() => import("pages/cart"));

export interface IRoute {
  path: string;
  access?: string[] | "*";
  element: JSX.Element;
  inner?: IRoute[];
  index?: boolean;
  title: string;
}

const privateRoutes: IRoute[] = [
  {
    path: "/",
    title: "Home page",
    element: <Home />,
  },
  {
    path: "/cart",
    title: "Cart page",
    element: <Cart />,
  },
  {
    path: "/categories",
    title: "Categories page",
    element: <Categories />,
  },
  {
    path: "/products/:id",
    title: "Product page",
    element: <Product />,
  },
  {
    path: "/payment",
    title: "Payment page",
    element: <Payment />,
  },
  {
    path: "/register",
    title: "Register page",
    element: <Register />,
  },
  {
    path: "*",
    title: "",
    element: <NotFound />,
  },
];

const publicRoutes: IRoute[] = [
  // {
  //   path: "/login",
  //   access: [],
  //   title: "Login",
  //   element: <Login />,
  // },
];

export { privateRoutes, publicRoutes };
