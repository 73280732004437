import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { initBackButton } from "@telegram-apps/sdk";

const TelegramBackButton = () => {
  const [bb, cleanupBb] = initBackButton();
  const navigate = useNavigate();

  useEffect(() => {
    bb.show();
    bb.on("click", () => {
      navigate(-1);
    });

    return () => {
      bb.hide();
    };
  }, [navigate, bb]);
  return null;
};

export default TelegramBackButton;
